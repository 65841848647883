// external dependencies
import React from 'react';

// local dependencies
import Hero from '../../components/shared/Hero';
import Cta from '../../components/shared/Cta';
import SEO from '../../components/SEO';
import TwoColNews from '../../components/shared/TwoColNews';
import { graphql, useStaticQuery } from 'gatsby';

// component definition
const NewsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      page: contentfulNewsPage {
        page
        newsModules {
          title
          slug
          supertext
          buttonText
          reverseOrder
          image {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
          file {
            file {
              url
            }
          }
          bodyCopy {
            bodyCopy
          }
        }
        ctaSection {
          email
          headline
          phoneNumber
          supertext
          image {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
        hero {
          buttonLink
          buttonText
          headline
          supertext
          image {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
        metaDescription
      }
    }
  `);

  const renderNews = data.page.newsModules.map((item, i) => {
    return (
      <TwoColNews
        key={i}
        image={item.image}
        superText={item.supertext}
        headline={item.title}
        body={item.bodyCopy.bodyCopy}
        buttonText={item.buttonText}
        fileUrl={item.file.file.url} // need to remove when complete
        buttonLink={item.slug}
        reverse={item.reverseOrder}
      />
    );
  });
  return (
    <>
      <SEO title={data.page.page} description={data.page.metaDescription} />
      <Hero
        short
        bkgImage={data.page.hero.image}
        headline={data.page.hero.headline}
        buttonLink={data.page.hero.buttonLink}
        buttonText={data.page.hero.buttonText}
        supertext={data.page.hero.supertext}
      />
      {renderNews}
      <Cta
        ctaSupertext={data.page.ctaSection.supertext}
        ctaHeadline={data.page.ctaSection.headline}
        phoneNumber={data.page.ctaSection.phoneNumber}
        email={data.page.ctaSection.email}
        ctaImage={data.page.ctaSection.image}
      />
    </>
  );
};

export default NewsPage;
